<template>
  <div v-for="entry in entries" :key="entry.year" class="mt-2">
    <VitaEntry :year="entry.year" :text="entry.text"> </VitaEntry>
    <hr class="mt-2" />
  </div>

  <p class="mt-4">Lebt und arbeitet in Berlin</p>
  <p class="mt-2">Zahlreiche Ausstellungen im In- und Ausland</p>
  <p class="mt-2">Arbeiten in privatem und öffentlichen Besitz</p>
</template>

<script>
import VitaEntry from "../components/Vita/VitaEntry.vue";

export default {
  name: "Vita",
  components: {
    VitaEntry,
  },
  data: function () {
    return {
      entries: this.$store.getters.getVitaEntries,
    };
  },
};
</script>

<style>
p {
  font-family: "Open Sans", sans-serif;
}
</style>
