<template>
  <div class="flex flex-row w-full justify-between">
    <div class="w-1/4">
      <p>{{ year }}</p>
    </div>
    <div class="w-3/4">
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "VitaEntry",
  props: {
    year: String,
    text: String,
  },
};
</script>

<style scoped>
p {
  font-family: "Open Sans", sans-serif;
}
</style>
